import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText,ManCard } from '@craft-design-system/core-components-react';
import ifav from './ifav.png';
// import { ManCard} from '@craft-design-system/core-components-react';

import { Button, Popover, OverlayTrigger } from 'react-bootstrap';

const Charging_power_fast = ({ value }) => {
  const [Charging_power_fast, set_Charging_power_fast] = useState(null);

//   //console.log('test',value)

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
      All time overview on average charging power vs. average battery temperature (system) for each charging cycle since start of data acquisition - If more than one vehicle is selected average values of all vehicles are displayed.

      </Popover.Body>
    </Popover>
  );


  useEffect(() => {
    try {
        set_Charging_power_fast(JSON.parse(value));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
    <ManCard className='card_battery' appearance="soft" outline="false">
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
     Charging Power (System) Vs Battery Temperature (System) during Fast Charging
      </ManText>
      <OverlayTrigger trigger="hover" placement="left" overlay={popover}>
      <img className="man-logovv1" src={ifav}/>
      </OverlayTrigger>



      {Charging_power_fast && <Plot data={Charging_power_fast.data} layout={Charging_power_fast.layout} />}    
      </ManCard>
  );
};

Charging_power_fast.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Charging_power_fast;