import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText,ManCard } from '@craft-design-system/core-components-react';
import ifav from './ifav.png';
// import { ManCard} from '@craft-design-system/core-components-react';

import { Button, Popover, OverlayTrigger } from 'react-bootstrap';

const SOH = ({ value }) => {
  const [soh, set_soh] = useState(null);

//   //console.log('test',value)

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
      The state of health (SoH) is a measure of battery capacity relative to when it was new. The SoH-Value is a linear combination of calendaric ageing loss and cyclic ageing loss. For individual vehicles it is visualized over time, since start of data acquisition. 

If more than one vehicle is selected, today's SoH of the selected vehicles is displayed in a scatter plot. The y-axis represents SoH loss in percent, the x-axis the time (days)  since start of data acquisition.
      </Popover.Body>
    </Popover>
  );
//   //console.log('test',soh)






  useEffect(() => {
    try {
        set_soh(JSON.parse(value));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
    <ManCard className='card_battery' appearance="soft" outline="false">
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
      SOH
      </ManText>
      <OverlayTrigger trigger="hover" placement="left" overlay={popover}>
      <img className="man-logovv1" src={ifav}/>
      </OverlayTrigger>



      {soh && soh.data && soh.data.length > 0 
    ? <Plot data={soh.data} layout={soh.layout} />
    :<ManText className="title" variant="body-l" as="span">
    KPI will be available soon
    </ManText>}    
      </ManCard>
  );
};

SOH.propTypes = {
  value: PropTypes.string.isRequired,
};

export default SOH;