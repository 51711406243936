import React, { useState,useEffect } from 'react';
import { ManText, ManDropdownMenu,ManSelect,ManSelectItem } from "@craft-design-system/core-components-react";
import { Col } from 'react-bootstrap';
import Truck_filters from './Truck_filters';
import Bus_filters from './Bus_filters';
import { useResetContext} from './ResetContext';
import { useFilterContext } from './FilterContext';
import { useGraphContext } from '../GraphContext';
const TabComponent_filters = ({initial_tab,dynamic_filter}) => {
  const {resetdata, updateresetdata} = useResetContext(); 
  const {filterdata, updateFilterData} = useFilterContext();
  const {graphdata, updateGraphData} = useGraphContext();
  useEffect(() => {
    updateFilterData(null);
    // updateGraphData(null);
  }, [resetdata]);

  const [selectedTab, setSelectedTab] = useState(initial_tab);
  updateresetdata(selectedTab);
  // updateFilterData([]);
  // console.log('resetdata',resetdata);
  
  const isDisabled = selectedTab === 'Bus'; // Change this condition based on your needs

  const tabStyle = {
    padding: '10px, 0px, 10px, 0px',
    cursor: 'pointer',
    fontWeight: 'bold',
    borderBottom: ' #d3d3d3 solid 1px',
    marginRight: '5px',
    width: '500px',
    display: 'flex',
    justifyContent: 'center',
    color:'black',
    background: 'white'
  };

  const selectedTabStyle = {
    ...tabStyle,
    borderBottom: 'red solid 2px',
    background: 'white',
    color: '#E40045'
  };

  const disabledTabStyle = {
    ...tabStyle,
    color: '#d3d3d3',
    cursor: 'not-allowed',
    pointerEvents: 'none'

  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-around' ,backgroundColor: 'white'}}>
        <span 
          style={selectedTab === 'Bus' ? selectedTabStyle : tabStyle} 
          onClick={() => !isDisabled && setSelectedTab('Bus')}
        >
          <ManText className="tabs1" variant={"heading-s"} as={"span"}>
            Bus
            </ManText>
        </span>
        <span 
          style={selectedTab === 'Truck' ? selectedTabStyle : tabStyle} 
          onClick={() => setSelectedTab('Truck')}
        >
        <ManText className="tabs1" variant={"heading-s"} as={"span"}>
            Truck
        </ManText>
        </span>
      </div>
        {selectedTab === 'Bus' && <div><Bus_filters selectedTab={selectedTab} /></div>}
        {selectedTab === 'Truck' && <div style={{color:'black'}}><Truck_filters selectedTab={selectedTab} /></div>}
    </div>
  );
};

export default TabComponent_filters;