import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText,ManCard } from '@craft-design-system/core-components-react';
import ifav from './ifav.png';
// import { ManCard} from '@craft-design-system/core-components-react';

import { Button, Popover, OverlayTrigger } from 'react-bootstrap';

const Cyclic_aging = ({ value }) => {
  const [cyclic_aging, set_cyclic_aging] = useState(null);

//   //console.log('test',value)

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
      The cyclic ageing matrix states the areas in which SoH loss related to Start-SoC and End-SoC of each discharging cycle since start of data acquisition took place. It is visible in which Start-Soc - End-SoC combination the vehicle(s) was mainly operating.

        Unit of matrix value:  %-SoH-Loss / (energy throughput^b)

        b describes how degressive the SoH loss rate is over time. In this case b equals 1, this means constant degredation over time.

        If more than one vehicle is selected average values are displayed in each matrix field.
      </Popover.Body>
    </Popover>
  );
//   //console.log('test',cyclic_aging)






  useEffect(() => {
    try {
        set_cyclic_aging(JSON.parse(value));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
    <ManCard className='card_battery' appearance="soft" outline="false">
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
      Cyclic Aging Matrix
      </ManText>
      <OverlayTrigger trigger="hover" placement="left" overlay={popover}>
      <img className="man-logovv1" src={ifav}/>
      </OverlayTrigger>

      {cyclic_aging && cyclic_aging.data && cyclic_aging.data.length > 0 
        ? <Plot data={cyclic_aging.data} layout={cyclic_aging.layout} />
        : <ManText className="title" variant="body-l" as="span">
          KPI will be available soon
        </ManText>
      }
    </ManCard>
  );
};

Cyclic_aging.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Cyclic_aging;