import {
    Charging_mode,
    Charging_power,
    Battery_temp_fast,
    Charging_power_fast,
    Charging_power_normal,
    Battery_temp_normal
}
from './charging_analysis_kpi'
import { useGraphContext } from '../GraphContext';
import { Card } from 'react-bootstrap';


const ChargingAnalysis = () => {
    const { graphData } = useGraphContext();

    const {
        charging_mode,
        system_charging_power_kw,
        battery_temp_during_fast_charging,
        charging_power_during_fast_charging,
        charging_power_during_normal_charging,
        battery_temp_during_normal_charging,
        vin_short
    }=graphData;
    //console.log(graphData)



  return (
    <div>
    <div className="card-info_charging">
    <Card className='firstcard_charging'>
    <Charging_mode value={charging_mode}/>
    </Card>
    <Card className='firstcard_charging'>
    <Charging_power value={{system_charging_power_kw,vin_short}}/>
    </Card>
    <Card className='firstcard_charging'>
    <Battery_temp_fast value={{battery_temp_during_fast_charging,vin_short}}/>
    </Card>
    <Card className='firstcard_charging'>
    <Battery_temp_normal value={{battery_temp_during_normal_charging,vin_short}}/>
    </Card>
    <Card className='firstcard_charging'>
    <Charging_power_fast value={charging_power_during_fast_charging}/>
    </Card>
    <Card className='firstcard_charging'>
    <Charging_power_normal value={charging_power_during_normal_charging}/>
    </Card>

    </div>
    
    </div>

    
  )
}

export default ChargingAnalysis;