import React, { useState } from 'react';
import Graphs from '../components/Graphs';
import useHandleQuery from "../utils/dropdown_data_loading/useHandleQuery";
import  Shimmer_graph_man from './MAN_info_graphs'
import graphicon from '../assets/images/graph.png';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { useFilterContext } from './FilterContext';


const YourComponent = ({selected_value}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { filterdata, updateFilterData } = useFilterContext();

  console.log('yoyrkdnkd',selected_value)
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const handleQuery = useHandleQuery();


  const handlegraphclick = async ({selectedValues}) => {
    //console.log('selected_value',selectedValues);
    setIsButtonClicked(true);
    setIsLoading(true);
    updateFilterData(prevState => ({
      ...prevState,
      button_clicked: true
    }));
    try {
      await handleQuery({ selectedValues });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }

    
  };
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        
      Load statistics based on filter selection
      </Popover.Body>
    </Popover>
  );

  return (
    <div className='graph_div'>
          <OverlayTrigger trigger="hover" placement="right" overlay={popover}>

        <button class="graph-button" onClick={() => handlegraphclick({ selectedValues: selected_value })}>
        <img src={graphicon} alt="button image" className='graph-icon'/>
          </button>
          </OverlayTrigger>
          {isLoading ? <Shimmer_graph_man /> : isButtonClicked && <Graphs />}
    </div>
  );
};

export default YourComponent;