// import Shimmer_graph from '../shimmer_graph';
import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
// import { colors } from 'react-select/dist/declarations/src/theme';



const Donut = ({value}) => {
    const [donut_chart, set_donut_chart] = useState(null);

    //console.log('value',value)

    //console.log(donut_chart)



  useEffect(() => {
    //console.log('testing23456', donut_chart);
  }, [donut_chart]);

  useEffect(() => {
    try {
        set_donut_chart(JSON.parse(value.donut_chart));
        //console.log('donut_chart',donut_chart)
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  let selectedText = '';
  if (value.donut_chart.category === 'B') {
    selectedText ='Buses';
  } else if (value.donut_chart.category === 'L') {
    selectedText = 'Trucks';
  }

  return (
    <div style={{display: 'flex','justifyContent':'center','background':'white','height':'430px'}} className='firstcard12'>
      <div>
    <Plot data={value.donut_chart.figure.data} layout={value.donut_chart.figure.layout} ></Plot>
    </div>
    <div style={{position:'relative','top':278,'left':-204,'height':100}}>
      <h6 style={{color:'rgb(228, 0, 69)',fontFamily:'MAN EUROPE_Bold'}}>{value.donut_chart.total_selected} Selected {selectedText}</h6>
      <h6 style={{color:'black',fontFamily:'MAN EUROPE_Bold'}}>{value.donut_chart.total_records} Total {selectedText}</h6> 
      </div>
      </div>
  );
};
export default Donut;