import { createContext, useContext, useState } from 'react';

const ResetContext = createContext();

export const ResetProvider = ({ children }) => {
  const [resetdata, setresetdata] = useState(null);
  const clearGraphData = () => setresetdata(null);


  const updateresetdata = (newresetdata) => {
    setresetdata(newresetdata);
  };

  return (
    <ResetContext.Provider value={{ resetdata, updateresetdata,clearGraphData }}>
      {children}
    </ResetContext.Provider>
  );
};

export const useResetContext = () => {
  return useContext(ResetContext);
};
