import { LogLevel } from '@azure/msal-browser';
import { Configuration } from "@azure/msal-browser";


function getAuthConfig() {
  // Determine the correct redirectUri and clientId based on some condition
  // This is just an example, replace with your own logic
  if (window.location.hostname === 'bev-prod.simbaanalytics-dev.rio.cloud') {
    return {
      redirectUri: "https://bev-prod.simbaanalytics-dev.rio.cloud/",
      clientId: "922484da-0f99-4351-aa37-dbb21a59caa3",
    };
  } else {
    return {
      redirectUri: "https://bev-dev.simbaanalytics-dev.rio.cloud/",
      clientId: "fe480ad0-82ab-4539-81df-e9bb179b596e",
    };
  }
}

const authConfig = getAuthConfig();

const msalConfig: Configuration = {
    auth: {
      clientId: authConfig.clientId,
      authority: "https://login.microsoftonline.com/ccc63daa-d84b-4abd-98fd-4eeb839de273",
      redirectUri: authConfig.redirectUri,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
};
  
export default msalConfig;


