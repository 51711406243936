import  { useState, useEffect } from 'react';
import { ManText, ManSelect, ManSelectItem,ManInputText} from "@craft-design-system/core-components-react";
import Shimmer from "./Shimmer";
import UseDropdownTrucks from '../utils/dropdown_data_loading/useDropdownTrucks';
import useHandleQuery from "../utils/dropdown_data_loading/useHandleQuery";
import useDynamicFiltering from '../utils/dropdown_data_loading/useDynamicFiltering';
import { Collapse } from 'reactstrap'; // or react-bootstrap
import filters from '../assets/images/filters.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import MAN_Update from'../assets/images/MAN_Update.svg';
import reload from '../assets/images/reload.png';
// import filter from '../assets/images/filter.jpg';
import submit from '../assets/images/submit.png';
import filter from '../assets/images/filter.png'
import 'react-tooltip/dist/react-tooltip.css'

import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { ManHelperText } from '@craft-design-system/core-components-react';

import { Tooltip as ReactTooltip } from 'react-tooltip'

import YourComponent from './newComponent';
import TabComponent_filters from './tabs_filter';
import { useFilterContext } from './FilterContext';
import { useDynamicContext } from './DynamicContext';
import { useResetContext } from './ResetContext';
import FilterSidebar from './FilterSidebar';
import { useDeselectContext } from './DeselectContext';

const Truck_filters= () => {

  const [selectedValues, setSelectedValues] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownData, setDropdownStates] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showText, setShowText] = useState(false);
  const [showTextData, setShowTextData] = useState(false);
  const {deselectdata, updatedeselectdata} = useDeselectContext();


  const {filterdata, updateFilterData} = useFilterContext();
  const {dynamicdata, updatedynamicdata} = useDynamicContext();
  const {resetdata, updateresetdata,clearGraphData} = useResetContext();
  // handlegraphclick = YourComponent();

  const { dynamicFiltering, dropdownInfo } = useDynamicFiltering();
  let {dropdowndata,fetchData,isRefreshing} = UseDropdownTrucks();
  console.log('truck_filters',resetdata);

  

  const handleSelectChange = (selectedOptions, columnName) => {
    setSelectedValues(prevValues => {
      const newValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
      const updatedValues = {
        ...prevValues,
        [columnName]: newValues,
        button_clicked: false
      };
      // Only update filter data if something actually changed
      updateFilterData(updatedValues);
      
    
      return updatedValues;
    });
    setIsInitialLoad(false);
};

  const customStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: 'red',  // Change this to your desired color
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'grey' : state.isFocused ? '#EAEDF3' : null,
      color: state.isSelected ? 'white' : 'black',

    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: '1px',
      borderWidth: '1px',
      borderColor: '#5B6F85',
      cursor: 'pointer',
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        // display: 'inline-block',
        // margin: '2px',
      };
    },
    valueContainer: (styles, { data }) => {
      return {
        ...styles,
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
      };
    },
  };

  const handleValuesChange = (minValue, maxValue) => {

    setSelectedValues(prevValues => {
      const updatedValues = {
        ...prevValues,
        'Total mileage [km]': [minValue,maxValue],
      };
      updateFilterData(updatedValues);
      return updatedValues;

    });

  };

  useEffect(() => {
    setDropdownStates(dropdowndata);
    setSelectedValues(null);

  }, [dropdowndata]);

  useEffect(() => {
    setDropdownStates(dynamicdata);
  }, [dynamicdata]);

  useEffect(() => {
    if (deselectdata !== null) {
      console.log('selected_valuesss',selectedValues)
      setSelectedValues((prevSelectedValues) => ({
        ...prevSelectedValues,
        [deselectdata]: [], // Clear all selected options for the respective dropdown
      }));
    }
  }, [deselectdata]);

  useEffect(() => {
    console.log('resetdata_truck',resetdata); 
    if (resetdata === 'Truck1') {
      fetchData();
      setDropdownStates(dropdowndata);
      setSelectedValues(null);
    }
  }, [resetdata]);





  if (!dropdownData || isRefreshing ) return <Shimmer />;

  return (
    <FilterSidebar
        dropdownData={dropdownData}
        selectedValues={selectedValues}
        handleSelectChange={handleSelectChange}
        customStyles={customStyles}
        onvaluesChange={handleValuesChange}

      />
  );
};

export default Truck_filters;
