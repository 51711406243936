import { createContext, useContext, useState } from 'react';

const DynamicContext = createContext();

export const DynamicProvider = ({ children }) => {
  const [dynamicdata, setdynamicdata] = useState(null);
  const clearGraphData = () => setdynamicdata(null);


  const updatedynamicdata = (newdynamicdata) => {
    setdynamicdata(newdynamicdata);
  };

  return (
    <DynamicContext.Provider value={{ dynamicdata, updatedynamicdata,clearGraphData }}>
      {children}
    </DynamicContext.Provider>
  );
};

export const useDynamicContext = () => {
  return useContext(DynamicContext);
};
