import { AwsRum, AwsRumConfig } from 'aws-rum-web';

export const initializeRUM = () => {
try {
  const config: AwsRumConfig = {
    sessionSampleRate: 1,
    identityPoolId: "eu-west-1:43c10c32-e602-4b67-b25d-d8f7eb932def",
    endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
    telemetries: ["performance","errors","http"],
    allowCookies: true,
    enableXRay: false
  };

  const APPLICATION_ID: string = 'fe4e3fc4-9d44-4e84-8646-d490b6ed2140';
  const APPLICATION_VERSION: string = '1.0.0';
  const APPLICATION_REGION: string = 'eu-west-1';

  const awsRum: AwsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
  console.log('AWS RUM initialized successfully');

} catch (error) {
    console.error('Error initializing AWS RUM', error);

  // Ignore errors thrown during CloudWatch RUM web client initialization
}
};