import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText } from '@craft-design-system/core-components-react';
import useGraphRadio from '../../utils/useGraphRadio';
import Shimmer_graph from '../shimmer_graph';
import ifav from './ifav.png';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';

const Battery_temp = ({ value }) => {
  const [battery_temp, set_battery_temp] = useState(null);
  const radio_query = useGraphRadio();
  const [radio_buttons_test, set_Radio_values] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
      The battery system temperature is displayed by three values: maximum value per day, average value per day and minimum value per day.

      If more than one vehicle is selected average values are displayed.
      </Popover.Body>
    </Popover>
  );

  const handleSelectChange1 = (event) => {
    set_Radio_values((prevValues) => ({
      ...prevValues,
      [event.detail.name]: event.detail.value,
    }));
  };

  useEffect(() => {
    if (radio_buttons_test) {
      setIsLoading(true);

      //console.log(value.vin_short)
      radio_query({vins_short:[value.vin_short],radio_value:radio_buttons_test,columnname:'Battery_temperature'}).then(data => {
        set_battery_temp(JSON.parse(data['jsonData']));
        setIsLoading(false);

      });
    }
  }, [radio_buttons_test]);

  useEffect(() => {
    //console.log('testing23456', battery_temp);
  }, [battery_temp]);

  //console.log('battery_temp',battery_temp)

  useEffect(() => {
    try {
      set_battery_temp(JSON.parse(value.battery_temp));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  //console.log('battery_temp',value.battery_temp)

  

  return (
    <div >
      <div className="card-title">
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
        Battery Temperature
      </ManText>
      <OverlayTrigger trigger="hover" placement="left" overlay={popover}>

      <img className="man-logovv1" src={ifav}/>
      </OverlayTrigger>

      <ManRadioButtonGroup 
        name="Battery_temp"
        invalid="false"
        onManChange={handleSelectChange1}
      >
        <ManRadioButton name="Battery_temp" label="Daily" value="Daily" checked />
        <ManRadioButton name="Battery_temp" label="Monthly" value="Monthly" />
        <ManRadioButton name="Battery_temp" label="Yearly" value="Yearly" />
      </ManRadioButtonGroup>
      </div>
      {isLoading ? <Shimmer_graph /> : battery_temp && <Plot data={battery_temp.data} layout={battery_temp.layout} />}    
      </div>
  );
};

Battery_temp.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Battery_temp;