import { ManText, ManDropdownMenu,ManSelect,ManSelectItem,ManSpinner } from "@craft-design-system/core-components-react";


const Shimmer = () => {
    return (
            <div className='sidebar1' style={{'marginTop':'70px'}}>

            <div className="card-info-shimmer_filter" style={{'marginLeft':'10px'}}>
                <ManSpinner size="l" variant="standard"></ManSpinner>

        

        </div>
        

        </div>
    )
}

export default Shimmer;