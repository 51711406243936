import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText,ManCard } from '@craft-design-system/core-components-react';
import ifav from './ifav.png';
// import { ManCard} from '@craft-design-system/core-components-react';

import { Button, Popover, OverlayTrigger } from 'react-bootstrap';

const A_cal_val = ({ value }) => {
  const [a_cal_val, set_man_part_no] = useState(null);

//   //console.log('test',value)

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
      The average calendaric aging factor (a_cal_avg) is the average value over all matrix elements. 

Unit of average calendaric aging factor:  %-SoH-Loss / (days^b)

If more than one vehicle is selected the distribution of a_cal_avg is displayed.
      </Popover.Body>
    </Popover>
  );
//   //console.log('test',a_cal_val)






  useEffect(() => {
    try {
        set_man_part_no(JSON.parse(value));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
    <ManCard className='card_battery' appearance="soft" outline="false">
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
      Calendaric Aging Factor
      </ManText>
      <OverlayTrigger trigger="hover" placement="left" overlay={popover}>
      <img className="man-logovv1" src={ifav}/>
      </OverlayTrigger>

      {a_cal_val && a_cal_val.data && a_cal_val.data.length > 0 
        ? <Plot data={a_cal_val.data} layout={a_cal_val.layout} />
        : <ManText className="title" variant="body-l" as="span">
          KPI will be available soon.
        </ManText>
      }
    </ManCard>
  );
};

A_cal_val.propTypes = {
  value: PropTypes.string.isRequired,
};

export default A_cal_val;