import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import newFeatureImage from '../assets/images/New_features_images/image.png';
import TotalMileageImage from '../assets/images/New_features_images/Total_mileage.png';
const NewFeaturesModal = () => {
  const [showModal, setShowModal] = useState(false);

  // Use localStorage to show the modal only once
  useEffect(() => {
    const isFirstVisit = localStorage.getItem('firstVisit');
    if (!isFirstVisit) {
      setShowModal(true);
      localStorage.setItem('firstVisit', 'true');
    }
  }, []);

  const handleClose = () => setShowModal(false);

  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Release 1.1 | 23/10/2024</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Enhancements & Bug Fixes</h5>
          <h6>1. Selected filter criteria visualization</h6>
          <p>
            From now on, selected filter criteria will be visualized on top of vehicle statistics.
            This aims to highlight the filter selections that are currently applied on the vehicle statistics.
            Users can continue using the filter bar on the side to define the vehicle selection. 
            The added visualization simply states which filters have been applied.
          </p>
          <h6>Visual Description:</h6>
          <img src={newFeatureImage} alt="Visual Description" style={{
          maxWidth: '100%', // Limit width to 100% of the modal
          maxHeight: '100%', // Limit height to 100% of the modal
          objectFit: 'contain', // Preserve aspect ratio, fit inside the modal
        }} />
          <br></br>
          <br></br>
          <br></br>

          <h6>2. New filter option: "Total mileage [km]"</h6>
          <p>
            A new filter option allows you to define the group of vehicles based on their total mileage. 
            It is listed at the very bottom of the filter sidebar.
          </p>
          <ul>
            <li><strong>Minimum value:</strong> "0" km</li>
            <li><strong>Maximum value:</strong> "500,000" km</li>
            <li><strong>Note:</strong> Negative values are not accepted. Values outside this range will be adjusted accordingly.</li>
          </ul>
          <img src={TotalMileageImage} alt="Visual Description" style={{
          maxWidth: '100%', // Limit width to 100% of the modal
          maxHeight: '100%', // Limit height to 100% of the modal
          objectFit: 'contain', // Preserve aspect ratio, fit inside the modal
        }} />
            <br></br>
          <br></br>
          <br></br>

          <h6>3. Bug Fixes</h6>
          <ul>
            <li>
              <strong>KPI "Average Charging Power (System)"</strong><br/>
              The unit when applying mouseover has been corrected.
            </li>
            <li>
              <strong>KPI "Energy Throughput per Speed Class"</strong><br/>
              The x-axis description for vehicle standing has been corrected.
            </li>
            <li>
              <strong>All KPIs</strong><br/>
              A message is now displayed if no data is available for a KPI according to the selected filters: 
              <em>"No data available for the current filters."</em> This aims to improve the user experience.
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  );
};

export default NewFeaturesModal;
