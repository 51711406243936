import React, { useState } from "react";

import { ManText, ManSelect, ManSelectItem,ManInputText} from "@craft-design-system/core-components-react";

const Yearly_mileage_filter = ({ onValuesChange }) => {
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(500000);
  const [disabled, setDisabled] = useState(false);

  const handleMinValueChange = (event) => {
    let value = parseInt(event.target.value);
    if (isNaN(value) || value < 0) {
      value = 0; // Prevent negative values or invalid input
    }
    setMinValue(value);
    onValuesChange(value, maxValue);
  };

  const handleMaxValueChange = (event) => {
    let value = parseInt(event.target.value);
    if (isNaN(value) || value > 500000) {
      value = 500000; // Limit to 500,000 or handle invalid input
    }
    setMaxValue(value);
    onValuesChange(minValue, value);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      {/* <h8 style={{ color: "black" }}>Yearly mileage [Km]</h8> */}
      <ManText className="filter-text" variant={"heading-s"} as={"span"}>
            Total mileage [km]
        </ManText>


      <div style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
        <div style={{ marginRight: "8px" }}>
        <ManText className="filter-text" variant={"heading-s"} as={"span"}>
          Min Value
          </ManText>
          <input
            type="number"
            value={minValue}
            onChange={handleMinValueChange}
            disabled={disabled}
            style={{ width: "100px", padding: "5px", color: "black" , marginTop: "5px" }}
          />
        </div>
        <div>
        <ManText className="filter-text" variant={"heading-s"} as={"span"}>
          Max Value
          </ManText>
          <input
            type="number"
            value={maxValue}
            onChange={handleMaxValueChange}
            disabled={disabled}
            style={{ width: "100px", padding: "5px", color: "black" , marginTop: "5px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Yearly_mileage_filter;
