import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { GraphProvider } from './GraphContext';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from "@azure/msal-react";
import msalConfig from './Auth_config';
import { FilterProvider } from './components/FilterContext';
import {DynamicProvider} from './components/DynamicContext';
import { ResetProvider } from './components/ResetContext';
import { DeselectProvider } from './components/DeselectContext';

const msalInstance = new PublicClientApplication(msalConfig);




const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <MsalProvider instance={msalInstance}>

  <BrowserRouter>
  <React.StrictMode>
      <GraphProvider>
      <DeselectProvider>
        <FilterProvider>
          <DynamicProvider>
            <ResetProvider>
              <App />
            </ResetProvider>
          </DynamicProvider>
        </FilterProvider>
        </DeselectProvider>

      </GraphProvider>
  </React.StrictMode>
  </BrowserRouter>
  </MsalProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
