import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText,ManCard } from '@craft-design-system/core-components-react';
import ifav from './ifav.png';
// import { ManCard} from '@craft-design-system/core-components-react';

import { Button, Popover, OverlayTrigger } from 'react-bootstrap';

const Calendric_aging = ({ value }) => {
  const [calendric_aging, set_man_part_no] = useState(null);

//   //console.log('test',value)

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
      The calendaric ageing matrix states the areas in which SoH loss related to Temperature and SoC since start of data acquisition took place. It is visible in which SoC-Temperature combination the vehicle(s) was mainly operating.

        Unit of matrix value:  %-SoH-Loss / (days^b)

        b describes how degressive the SoH loss rate is over time.

        If more than one vehicle is selected average values are displayed in each matrix field.

      </Popover.Body>
    </Popover>
  );
//   //console.log('test',calendric_aging)






  useEffect(() => {
    try {
        set_man_part_no(JSON.parse(value));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
    <ManCard className='card_battery' appearance="soft" outline="false">
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
      Calendric Aging Matrix
      </ManText>
      <OverlayTrigger trigger="hover" placement="left" overlay={popover}>
      <img className="man-logovv1" src={ifav}/>
      </OverlayTrigger>

      {calendric_aging && calendric_aging.data && calendric_aging.data.length > 0 
        ? <Plot data={calendric_aging.data} layout={calendric_aging.layout} />
        : <ManText className="title" variant="body-l" as="span">
          KPI will be available soon
        </ManText>
      }
    </ManCard>
  );
};

Calendric_aging.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Calendric_aging;