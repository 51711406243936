import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText } from '@craft-design-system/core-components-react';
import useGraphRadio from '../../utils/useGraphRadio';
import Shimmer_graph from '../shimmer_graph';
import PopoverComponent from '../popover';

const DailyMileage = ({ value }) => {
  const [daily_mileage, set_daily_mileage] = useState(null);
  const radio_query = useGraphRadio();
  const [radio_buttons_test, set_Radio_values] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectChange1 = (event) => {
    set_Radio_values((prevValues) => ({
      ...prevValues,
      [event.detail.name]: event.detail.value,
    }));
  };


  useEffect(() => {
    if (radio_buttons_test) {
      setIsLoading(true);

      //console.log(value.vin_short)
      radio_query({vins_short:[value.vin_short],radio_value:radio_buttons_test,columnname:'Daily Mileage'}).then(data => {
        set_daily_mileage(JSON.parse(data['jsonData']));
        setIsLoading(false);

      });
    }
  }, [radio_buttons_test]);

  useEffect(() => {
    //console.log('testing23456', daily_mileage);
  }, [daily_mileage]);

  useEffect(() => {
    try {
      set_daily_mileage(JSON.parse(value.daily_mileage));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
    <div >
      <div className="card-title">
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
      Daily Mileage
      </ManText>
      <PopoverComponent id= 'Daily_mileage' content='This KPI displays the driven distance of the vehicle within one calendar day. 
If more than one vehicle is selected average values are displayed.' />

      <ManRadioButtonGroup 
        name="Daily Mileage"
        invalid="false"
        onManChange={handleSelectChange1}
      >
        <ManRadioButton name="Daily Mileage" label="Daily" value="Daily" checked />
        <ManRadioButton name="Daily Mileage" label="Monthly" value="Monthly" />
        <ManRadioButton name="Daily Mileage" label="Yearly" value="Yearly" />
      </ManRadioButtonGroup>
      </div>
      {isLoading ? <Shimmer_graph /> : daily_mileage && <Plot data={daily_mileage.data} layout={daily_mileage.layout}

      
      />}    
      </div>
  );
};


DailyMileage.propTypes = {
  value: PropTypes.string.isRequired,
};

export default DailyMileage;