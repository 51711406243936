import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText } from '@craft-design-system/core-components-react';
import useGraphRadio from '../../utils/useGraphRadio';
import Shimmer_graph from '../shimmer_graph';
import ifav from './ifav.png';

import { Button, Popover, OverlayTrigger } from 'react-bootstrap';

const BarChart = ({ value }) => {
  const [median_consumption_graph, set_median_consumption_graph] = useState(null);
  const radio_query = useGraphRadio();
  const [radio_buttons_test, set_Radio_values] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectChange1 = (event) => {
    set_Radio_values((prevValues) => ({
      ...prevValues,
      [event.detail.name]: event.detail.value,
    }));
  };
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
      The energy throughput consists of energy consumption and recuperated energy. 

If more than one vehicle is selected average values are displayed.      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    if (radio_buttons_test) {
      setIsLoading(true);

      //console.log(value.vin_short)
      radio_query({vins_short:[value.vin_short],radio_value:radio_buttons_test,columnname:'Average Consumption'}).then(data => {
        set_median_consumption_graph(JSON.parse(data['jsonData']));
        setIsLoading(false);

      });
    }
  }, [radio_buttons_test]);

  useEffect(() => {
    //console.log('testing23456', median_consumption_graph);
  }, [median_consumption_graph]);

  useEffect(() => {
    try {
      set_median_consumption_graph(JSON.parse(value.median_consum));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
    <div >
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
      Energy Throughput
      </ManText>
      <OverlayTrigger trigger="hover" placement="left" overlay={popover}>
      <img className="man-logovv1" src={ifav}/>
      </OverlayTrigger>



      <ManRadioButtonGroup 
        name="Average Consumption"
        invalid="false"
        onManChange={handleSelectChange1}
      >
        <ManRadioButton name="Energy Throughput" label="Daily" value="Daily" checked />
        <ManRadioButton name="Energy Throughput" label="Monthly" value="Monthly" />
        <ManRadioButton name="Energy Throughput" label="Yearly" value="Yearly" />
      </ManRadioButtonGroup>


      {isLoading ? <Shimmer_graph /> : median_consumption_graph && <Plot data={median_consumption_graph.data} layout={median_consumption_graph.layout} 
      
      />}    
      </div>
  );
};

BarChart.propTypes = {
  value: PropTypes.string.isRequired,
};

export default BarChart;