import { createContext, useContext, useState } from 'react';

const DeselectContext = createContext();

export const DeselectProvider = ({ children }) => {
  const [deselectdata, setdeselectdata] = useState(null);
  const clearGraphData = () => setdeselectdata(null);


  const updatedeselectdata = (newdeselectdata) => {
    setdeselectdata(newdeselectdata);
  };

  return (
    <DeselectContext.Provider value={{ deselectdata, updatedeselectdata,clearGraphData }}>
      {children}
    </DeselectContext.Provider>
  );
};

export const useDeselectContext = () => {
  return useContext(DeselectContext);
};
