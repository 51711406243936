import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText,ManCard } from '@craft-design-system/core-components-react';
import ifav from './ifav.png';
// import { ManCard} from '@craft-design-system/core-components-react';

import { Button, Popover, OverlayTrigger } from 'react-bootstrap';

const Efficiency = ({ value }) => {
  const [efficiency, set_soc] = useState(null);

//   //console.log('test',value)

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <ul>
          <li>The efficiency is defined as:</li>
          <ul>
            <li>Efficiency = Consumed energy / (Charged energy + recuperated energy)</li>
          </ul>
          <li>All energy values in the formula represent cumulative energies over time (since data collection began). Therefore, the efficiency value needs a certain amount of time to settle at the right level.</li>
          <li>For individual vehicles, the efficiency is visualized over time, since the start of data acquisition.</li>
          <li>If more than one vehicle is selected, today's efficiency of the selected vehicles is displayed in a scatter plot.</li>
          <ul>
            <li>The y-axis represents efficiency in percent.</li>
            <li>The x-axis represents the time (days) since the start of data acquisition.</li>
          </ul>
        </ul>
      </Popover.Body>
    </Popover>
  );
//   //console.log('test',efficiency)






  useEffect(() => {
    try {
        set_soc(JSON.parse(value));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
    <ManCard className='card_battery' appearance="soft" outline="false">
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
      Efficiency
      </ManText>
      <OverlayTrigger trigger="hover" placement="left" overlay={popover}>
      <img className="man-logovv1" src={ifav}/>
      </OverlayTrigger>

      {efficiency && efficiency.data && efficiency.data.length > 0 
        ? <Plot data={efficiency.data} layout={efficiency.layout} />
        : <ManText className="title" variant="body-l" as="span">
            KPI will be available soon
          </ManText>
      }
    </ManCard>
  );
};

Efficiency.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Efficiency;